import React from 'react';
import './App.css';
import './hover.css';
import Navigation from './Components/Default/Navigation';
import Footer from './Components/Default/Footer';
import Index from './Components/Default/Index';
import { BrowserRouter as Router , Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <Router>
            <Navigation/>
                  <Route exact path="/" component={Index} />
            <Footer/>
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App;
