import React from 'react';
import { useRecoilValue } from 'recoil';
import { project } from '../store';
import useSwr from "swr";
import { Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';


const ProjectPage = (props) => {
    const fetcher = (...args) => fetch(...args).then(response => response.json());
    const projectSelected = useRecoilValue(project);
    let name = "Code_Challenge_Xml";
    if(projectSelected[0] !== undefined){
        name = projectSelected[0].name;
    }
    const { data } = useSwr(`https://api.github.com/repos/Dylanseery17/${name}/readme`, { fetcher });
    let contents;
    if(data){
        contents = atob(data.content);
    }
    return (
        <div>
            {projectSelected.map((project, index) => {
                return(
                    <div style={{minHeight:'50vh'}} name="project" key={index} id="project">
                        <Col sm={12}>
                            <ReactMarkdown source={contents} />
                        </Col>
                    </div> 
                )
            })}
        </div>
    )
}

export default ProjectPage
