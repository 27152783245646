import React, { useState }  from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const Contact = () => {
    
    const [contactData, setcontactData] = useState({
        name: '',
        email: '',
        message: ''
      });
    const {name, email, message} = contactData;
    const onChange = e => setcontactData({ ...contactData, [e.target.name]: e.target.value });
    const onSubmit = async e =>{
        e.preventDefault();
        axios.post('https://us-central1-dylanseeryport.cloudfunctions.net/sendMail', 
        {
            name,
            email,
            message
        })
          .then((response) => {
            console.log(response);
          }, (error) => {
            console.log(error);
          });
        
        setcontactData({
            name: '',
            email: '',
            message: ''
        });
    }

    return (
        <div id="Contact" name="contact" className="animate__animated animate__fadeInUp animate__delay-2s">
            <hr></hr>
            <Container style={{marginBottom: '10px'}}>
                <Row>
                    <Col>
                        <h1>Contact me</h1>
                        <hr></hr>
                        <Form action="" onSubmit={e => onSubmit(e)}>
                        <Form.Group controlId="formBasicName" style={{margin: '20px'}}>
                            <Form.Label>Name / Organistion</Form.Label>
                            <Form.Control type="text" value={name} name="name" onChange={e=>onChange(e)} placeholder="Your Name / Organistion" />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" style={{margin: '20px'}}>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" value={email} name="email" onChange={e=>onChange(e)} placeholder="Your email" />
                        </Form.Group>
                        <Form.Group controlId="formBasicMessage" style={{margin: '20px'}}>
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows="3" value={message} name="message" onChange={e=>onChange(e)} placeholder="Message Here" />
                        <Button variant="outline-dark" type="submit" block className="hvr-icon-bounce" style={{marginTop: '20px'}}>Submit &ensp;
                            <i className="fas fa-check-circle hvr-icon"></i> 
                        </Button>
                        </Form.Group>
                        </Form>
                        <hr></hr>
                    </Col>
                </Row>
                <Form.Group controlId="formBasicName" style={{margin: '20px'}}>
                <Row>
                    <Col sm={12}>
                    <Button variant="outline-primary" className="hvr-icon-bounce" href="https://www.linkedin.com/in/dylan-seery-7aaa26178" block>
                            LinkedIn &ensp;<i className="fab fa-linkedin hvr-icon"></i> 
                    </Button>
                    </Col>
                    <Col sm={12} md={6} style={{marginTop: '10px'}} className="d-md-none d-sm-block">
                    <Button variant="outline-success" className="hvr-icon-bounce" href="https://wa.me/+353852368369" block>
                            Whatsapp &ensp;<i className="fab fa-whatsapp hvr-icon"></i> 
                    </Button>
                    </Col>
                </Row>
                </Form.Group>
            </Container>
        </div>
    )
}

export default Contact
