export function parseDate(date){
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];
    const dt = date.split('-');
    const str = monthNames[parseInt(dt[1])-1] + ' - ' + dt[0]
    return str;
}

export function parseTitle(title){
    let str = title.replace(/_/g, " ").replace(/-/g, " ");
    str = str.replace(str[0], str[0].toUpperCase());
    return str;
}