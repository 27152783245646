import React from 'react';
import { Container, Row, Col, Image, Modal, Button, ListGroup } from 'react-bootstrap';

function calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); 
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Final year modules <i class="fas fa-graduation-cap text-secondary"></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ListGroup variant="flush">
                <ListGroup.Item>Major Project</ListGroup.Item>
                <ListGroup.Item>Research Skills</ListGroup.Item>
                <ListGroup.Item>Derivation of Algorithms</ListGroup.Item>
                <ListGroup.Item>Ubiquitous Computing</ListGroup.Item>
                <ListGroup.Item>Applied Human Language Technology</ListGroup.Item>
                <ListGroup.Item>Data Analytics</ListGroup.Item>
                <ListGroup.Item>Text Analysis</ListGroup.Item>
                <ListGroup.Item>Web Services</ListGroup.Item>
                <ListGroup.Item>Computational Intelligence</ListGroup.Item>
                <ListGroup.Item>Computer Vision</ListGroup.Item>
            </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant='dark'>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}

const About = () => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div id="About" name="about" className="animate__animated animate__fadeInUp animate__delay-1s">
            <Container>
                <Row>
                    <Col>
                        <h1>About me</h1>
                        <p>My name is <strong>Dylan Seery</strong> and I've recently finished my final year studying Computing at Technological University Dublin. I am <strong>{calculateAge(new Date('1998/09/08'))}</strong> years old. In my spare time I like to listen to podcasts, read up about the latest tech and go to the gym.</p>
                        <p>I am currently in the process of searching for a developer position, too being my career as a software developer! Since finishing my degree I have been researching some projects to undertake over the coming months to improve my skillset when it comes to software development. Click <span style={{color:'#ff7979', cursor: 'pointer'}} onClick={() => setModalShow(true)}>Here</span> for some of the modules I have taken in my final year of college.</p>
                        <p>Be sure to follow me on <a href="https://github.com/Dylanseery17" style={{color:'#ff7979', cursor: 'pointer'}}>GitHub</a> to see my future projects and connect with me on <a href="https://www.linkedin.com/in/dylan-seery-7aaa26178" style={{color:'#ff7979', cursor: 'pointer'}}>LinkedIn</a>.</p>
                    </Col>
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                    <Col style={{textAlign:'center'}} className="d-none d-md-block">
                        <Image thumbnail style={{width:'50%', marginTop: '80px'}} src="About.png"></Image>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default About
