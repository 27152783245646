import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ProjectContainer from './Projects/ProjectContainer';
import ProjectTable from './Projects/ProjectTable';
import ProjectPage from './Projects/ProjectPage';

const Projects = () => {
    const [toggled, setToggled] = React.useState(true);
    return (
        <div id="Projects" name="projects" className="animate__animated animate__fadeInUp animate__delay-2s">
            <hr></hr>
            <Container>
                <Row>
                    <Col>
                        <h1 style={{float:"left"}}>Project's</h1>
                        <h6 className="text-secondary" style={{float:"right", marginTop: '13.5px'}}>Gathered from <Button style={{marginTop: '-5px'}} variant="outline-secondary" href="https://github.com/Dylanseery17">Github <i className="fab fa-github"></i></Button></h6>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col>
                        {toggled ? 
                            <ProjectContainer/>
                        :
                            <ProjectTable/>
                        }
                        <Form style={{float: 'right'}}>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="View as table"
                                onChange={(e) => { setToggled(!toggled) }}
                            />
                        </Form>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col>
                        <ProjectPage title={'test'}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Projects
