import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import useSwr from "swr";
import ProjectCard from './ProjectCard';
import { Spinner, Container } from 'react-bootstrap';

const ProjectContainer = () => {
    const fetcher = (...args) => fetch(...args).then(response => response.json());
    const { data } = useSwr('https://api.github.com/users/Dylanseery17/repos', { fetcher });
    const settings = {
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        useCSS: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            }
          ]
      };
    return (
        <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Slider {...settings}>
                { data ? 
                    data.map((project, i) => {
                        return(
                            <div key={i}>
                                <ProjectCard project={project}></ProjectCard>
                            </div>
                        )
                    }) :
                    <Container style={{textAlign: 'center', marginTop: '10px', marginBottom: '10px'}}>
                            <Spinner animation="border" variant="danger" />
                    </Container>
                }
            </Slider>
        </div>
    )
}

export default ProjectContainer
