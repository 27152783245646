import React from 'react'
import useSwr from "swr";
import { Spinner, Container, Table, ButtonGroup, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { useRecoilState } from 'recoil';
import { project } from '../store';
import { parseTitle } from '../../../utils/utils'

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <p>{props.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant='dark'>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ProjectTable = () => {
    const fetcher = (...args) => fetch(...args).then(response => response.json());
    const { data } = useSwr('https://api.github.com/users/Dylanseery17/repos', { fetcher });
    const [modalShow, setModalShow] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [pro, setPro] = useRecoilState(project);

    function onClickTable(project){
        setPro([project]);
    };

    return (
        <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Table striped bordered hover size="sm" responsive>
              <thead>
                <tr>
                  <th>Project Title</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                  { data ? 
                      data.map((project, i) => {
                          let icon = "devicon-"+project.language.toLowerCase()+"-plain";
                          if(project.language.toLowerCase() === "html"){
                              icon = "devicon-"+project.language.toLowerCase()+"5-plain";
                          }
                          return(
                                  <tr key={i}>
                                    <td>{parseTitle(project.name)} <div className="d-none d-md-block"><span style={{color:'#e67e22', fontSize: '16px', float: 'right'}} ><i className="fas fa-star"></i> {project.stargazers_count}</span> <i style={{color:'#ff7979', fontSize: '16px', float: 'right', marginTop: '5px', marginRight: '5px'}} className={icon} title={project.language}></i></div></td>
                                    <td>
                                      <ButtonGroup role="group" className="special" size="sm">
                                          <Button variant="dark" onClick={() => {setModalShow(true); setTitle(parseTitle(project.name)); setDescription(project.description); }}>Description</Button>
                                          <Button variant="dark"><Link onClick={() => onClickTable(project)}activeClass="active" to="project" spy={true} smooth={true} offset={-75} duration={1000}>More Info</Link></Button>
                                          <Button variant="dark" href={project.html_url}>GitHub</Button>
                                      </ButtonGroup>
                                      {project.homepage ? 
                                          <Button variant="secondary" href={project.homepage} block size="sm" id="Live">View Live</Button>
                                          : 
                                          null
                                      }
                                </td>
                                  </tr>
                          )
                      }) :
                      <Container style={{textAlign: 'center', marginTop: '10px', marginBottom: '10px'}}>
                              <Spinner animation="border" variant="danger" />
                      </Container>
                  }
              </tbody>
            </Table>
            <MyVerticallyCenteredModal
              show={modalShow}
              description={description}
              title={title}
              onHide={() => setModalShow(false)}
            />
        </div>
    )
}

export default ProjectTable
