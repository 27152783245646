import React from 'react'
import { Navbar, Row, Container, Col } from 'react-bootstrap';

const Footer = () => {
    return (
            <Navbar expand="lg" bg="dark" variant="dark" style={{marginTop: '30px'}} className="animate__animated animate__fadeIn animate__delay-2s">
            <Container>
                <Row style={{width: '100%'}}>
                    <Col xs={12} style={{color: 'white', textAlign: 'center'}}>
                        <br/>
                        <h6  style={{margin: '0 auto'}} >Copyright &copy; Dylan Seery, {new Date().getFullYear()}. All rights reserved.</h6>
                        <h6  style={{margin: '0 auto'}} >Built with React <i className="devicon-react-original colored"></i> and Firebase <img src="https://cdn.worldvectorlogo.com/logos/firebase-1.svg" style={{width: '11px'}}></img></h6>
                        <br/>
                    </Col>
                </Row>
            </Container>
            </Navbar>
    )
}

export default Footer
