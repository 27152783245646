import React from 'react';
import { Jumbotron, Container, Button, Row, Col } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';

const Header = () => {
    return (
        <div>
            <Jumbotron fluid id="Header" name="header">
                <Container>
                    <h1 style={{'font-family': 'Oswald'}}>Dylan Seery</h1> <br/>
                    <h4>
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                  .start()
                                  .pauseFor(125)
                                  .typeString("<strong style='color:#ff7979; text-shadow: 1px 1px 4px #000000;'>Software Developer</strong>");
                              }}
                        />
                    </h4><br/>
                    <Row>
                        <Col sm={6} lg={3} style={{marginTop: '5px'}} className="hvr-icon-down">
                            <Button variant="dark" href="DylanSeeryCV.pdf" download  block>Download my CV <i className="fas fa-file-download hvr-icon" style={{float: 'left', marginTop: '3px', marginLeft: '3px'}}></i></Button>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop: '5px'}} className="hvr-icon-spin">                        
                            <Button variant="dark" href="mailto:dylanseerydev@gmail.com" block>Send me an email <i className="fas fa-paper-plane hvr-icon" style={{float: 'left', marginTop: '3px', marginLeft: '3px'}}></i> </Button>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </div>
    )
}

export default Header
