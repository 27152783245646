import React from 'react'
import { Card, Col, Row, Container, Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { useRecoilState } from 'recoil';
import { project } from '../store';
import {parseDate , parseTitle} from '../../../utils/utils'

const ProjectCard = (props) => {
    const [pro, setPro] = useRecoilState(project);

    const onClick = (event) => {
        setPro([props.project]);
    };

    let icon = "devicon-"+props.project.language.toLowerCase()+"-plain";
    if(props.project.language.toLowerCase() === "html"){
        icon = "devicon-"+props.project.language.toLowerCase()+"5-plain";
    }
    return (
        <div style={{marginTop: '5px', marginBottom: '5px'}}>
            <Container>
                <Row>
                    <Col>
                        <Card style={{height: '350px'}}>
                        <Card.Body>
                            <Card.Title style={{height: '50px'}}>{parseTitle(props.project.name)}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted" style={{float: 'left'}}>{props.project.language} &ensp;<i style={{color:'#ff7979', fontSize: '16px'}} className={icon} title={props.project.language}></i></Card.Subtitle>
                            <Card.Subtitle className="mb-2" style={{float: 'right', color:'#e67e22'}}> <i className="fas fa-star"></i> {props.project.stargazers_count}</Card.Subtitle><br/><hr/>
                            <Card.Text style={{height: '75px'}}>
                                {props.project.description}
                            </Card.Text>
                            <span className="mb-2 text-muted">
                                    {parseDate(props.project.created_at)}
                            </span>
                            <div style={{position: 'absolute', bottom: '0', marginBottom: '15px', width: '90%'}}>
                                <hr/>
                                <ButtonGroup role="group" className="special">
                                    <Button variant="outline-dark"><Link activeClass="active" to="project" onClick={onClick} spy={true} smooth={true} offset={-75} duration={1000}>More Info</Link></Button>
                                    <Button variant="outline-dark" href={props.project.html_url}>GitHub</Button>
                                    {props.project.homepage ? 
                                    <Button variant="outline-dark" href={props.project.homepage}>View Live</Button>
                                    : 
                                    <Button variant="outline-dark" disabled id="notLive">Not live</Button>
                                    }
                                </ButtonGroup>
                            </div>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ProjectCard
